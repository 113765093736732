import React from 'react';
import { Calendar, MapPin, Mail, ExternalLink, Snowflake, Camera } from 'lucide-react';

const TellurideEvents = () => {
  const eventCalendars = [
    {
      name: "Telluride Official Events Calendar",
      description: "Complete listing of events in Telluride",
      url: "https://www.telluride.com/festivals-events/events/"
    },
    {
      name: "Mountain Village Events",
      description: "Events in the Mountain Village area",
      url: "https://townofmountainvillage.com/explore/events/all-events/"
    }
  ];

  const skiingLinks = [
    {
      name: "Snow Report",
      description: "Current conditions and snow totals",
      url: "https://tellurideskiresort.com/snow-report-scrape/"
    },
    {
      name: "Ski Passes",
      description: "Lift tickets and season passes",
      url: "https://tellurideskiresort.com/ski/"
    },
    {
      name: "Epic Pass",
      description: "Access Telluride and other world-class resorts",
      url: "https://www.epicpass.com/"
    }
  ];

  const handleMapClick = () => {
    window.open('https://www.google.com/maps?q=telluride,co', '_blank');
  };

  const scrollToEvents = () => {
    const eventsSection = document.querySelector('#events-section');
    eventsSection.scrollIntoView({ behavior: 'smooth' });
  };

  const Hero = () => (
    <div className="relative h-[600px]">
      <picture className="absolute inset-0">
        <source
          srcSet="/images/tride3840.webp"
          media="(min-width: 1920px)"
          type="image/webp"
        />
        <source
          srcSet="/images/tride1920.webp"
          media="(min-width: 1280px)"
          type="image/webp"
        />
        <source
          srcSet="/images/tride1280.webp"
          media="(min-width: 768px)"
          type="image/webp"
        />
        <img
          src="/images/tride768.webp"
          alt="Telluride mountain landscape"
          className="w-full h-full object-cover"
        />
      </picture>
      <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/30"></div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center h-full text-center">
        <h1 className="text-5xl md:text-7xl font-bold tracking-tight text-white animate-fade-in">
          Telluride Colorado
        </h1>
        <p className="mt-8 text-xl md:text-2xl text-gray-200 max-w-3xl font-light">
          Experience the magic of Colorado's most beautiful mountain town
        </p>
        <button 
          onClick={scrollToEvents}
          className="mt-8 px-8 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition-all transform hover:scale-105 duration-200"
        >
          Explore Events
        </button>
      </div>
    </div>
  )

  const EventCard = ({ calendar }) => (
    <a 
      href={calendar.url}
      target="_blank"
      rel="noopener noreferrer"
      className="block group relative overflow-hidden rounded-xl border border-gray-200 hover:border-blue-500 transition-all duration-300 bg-white hover:shadow-xl"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600/0 via-blue-600/0 to-blue-600/0 group-hover:from-blue-600/5 group-hover:via-blue-600/5 group-hover:to-blue-600/5 transition-all duration-300"></div>
      <div className="p-8">
        <h3 className="text-xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200">
          {calendar.name}
        </h3>
        <p className="mt-3 text-gray-600">{calendar.description}</p>
        <div className="mt-4 flex items-center text-blue-600">
          <span className="text-sm font-medium">Learn more</span>
          <ExternalLink className="ml-2 h-4 w-4" />
        </div>
      </div>
    </a>
  )

  const SectionHeader = ({ icon: Icon, title }) => (
    <div className="flex items-center space-x-4 mb-8">
      <div className="p-3 bg-blue-100 rounded-lg">
        <Icon className="h-6 w-6 text-blue-600" />
      </div>
      <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
    </div>
  )

  const WebcamSection = () => (
    <div id="webcam-section" className="bg-white rounded-2xl shadow-xl overflow-hidden">
      <div className="p-8">
        <SectionHeader icon={Camera} title="Live Mountain View" />
        <div className="relative aspect-video rounded-xl overflow-hidden">
          <iframe
            title="Telluride Village Webcam"
            className="w-full h-full"
            src="https://tellurideskiresort.com/webcams/village-cam/"
            allowFullScreen
          ></iframe>
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
            <p className="text-white text-sm">Live view of Mountain Village</p>
          </div>
        </div>
      </div>
    </div>
  )

  const Footer = () => (
    <footer className="bg-gray-900 text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div>
            <h3 className="text-2xl font-bold mb-4">Telluride Events</h3>
            <p className="text-gray-400">Your guide to everything happening in Telluride, Colorado.</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-gray-400">
              <li><a href="#events-section" className="hover:text-white transition-colors">Events</a></li>
              <li>
                <a 
                  href="https://issuu.com/visit_telluride/docs/ttb-w24-25-issuu" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="hover:text-white transition-colors"
                >
                  Winter Visitor Guide
                </a>
              </li>
              <li><a href="#skiing-section" className="hover:text-white transition-colors">Skiing</a></li>
              <li><a href="#webcam-section" className="hover:text-white transition-colors">Webcam</a></li>
              
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Telluride Mobile App</h4>
            <div className="flex flex-col space-y-4">
              <a 
                href="https://apps.apple.com/us/app/telluride-ski-resort/id1436432214"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-3 text-gray-400 hover:text-white transition-colors"
              >
                <img src="/images/pick.webp" alt="Telluride Pick Axe" className="w-6 h-6" />
                <span>Download on App Store</span>
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=com.resortstapped.TELLURIDE"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-3 text-gray-400 hover:text-white transition-colors"
              >
                <img src="/images/pick.webp" alt="Telluride Pick Axe" className="w-6 h-6" />
                <span>Get it on Google Play</span>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>© 2024 Telluride Events. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <Hero />

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Event Calendars Section */}
        <div id="events-section" className="bg-white rounded-lg shadow-lg p-6 mb-12">
          <div className="flex items-center mb-6">
            <Calendar className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-900">Event Calendars</h2>
          </div>
          <div className="space-y-6">
            {eventCalendars.map((calendar, index) => (
              <EventCard key={index} calendar={calendar} />
            ))}
            <p className="text-sm text-gray-500 mt-4">
              Click on a calendar link above to see the most up-to-date events in Telluride and Mountain Village.
            </p>
          </div>
        </div>

        {/* Winter Guide Section */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-12">
          <div className="flex items-center mb-6">
            <Calendar className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-900">Winter Visitor Guide</h2>
          </div>
          <a 
            href="https://issuu.com/visit_telluride/docs/ttb-w24-25-issuu"
            target="_blank"
            rel="noopener noreferrer"
            className="group block relative overflow-hidden rounded-xl border border-gray-200 hover:border-blue-500 transition-all duration-300"
          >
            <div className="flex flex-col md:flex-row items-center p-6">
              <div className="w-full md:w-1/3 mb-4 md:mb-0">
                <img
                  src="/images/visitguide.webp"
                  alt="2024/2025 Winter Visitor Guide Cover"
                  className="rounded-lg shadow-md transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="w-full md:w-2/3 md:pl-8">
                <h3 className="text-xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200">
                  2024/2025 Winter Visitor Guide
                </h3>
                <p className="mt-3 text-gray-600">
                  Your comprehensive guide to winter in Telluride. Discover lodging, dining, activities, and everything you need to plan your perfect mountain getaway.
                </p>
                <div className="mt-4 flex items-center text-blue-600">
                  <span className="text-sm font-medium">View Guide</span>
                  <ExternalLink className="ml-2 h-4 w-4" />
                </div>
              </div>
            </div>
          </a>
        </div>

        {/* Skiing Section */}
        <div id="skiing-section" className="bg-white rounded-lg shadow-lg p-6 mb-12">
          <div className="flex items-center mb-6">
            <Snowflake className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-900">Skiing</h2>
          </div>
          <div className="space-y-6">
            {skiingLinks.map((link, index) => (
              <a 
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-6 border rounded-lg hover:shadow-md transition-shadow duration-200 relative group"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-lg font-medium text-blue-600 group-hover:text-blue-800 transition-colors duration-200">
                      {link.name}
                    </h3>
                    <p className="text-gray-600 mt-1">{link.description}</p>
                  </div>
                  <ExternalLink className="h-5 w-5 text-gray-400 group-hover:text-blue-600 transition-colors duration-200" />
                </div>
              </a>
            ))}
          </div>
        </div>

        {/* Webcam Section */}
        <WebcamSection />

        {/* Map Section */}
        <div className="bg-white rounded-lg shadow-lg p-6 mb-12">
          <div className="flex items-center mb-6">
            <MapPin className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-900">Location</h2>
          </div>
          <div className="aspect-w-16 aspect-h-9 relative group cursor-pointer" onClick={handleMapClick}>
            <iframe
              title="Map of Telluride, Colorado"
              className="w-full h-96 rounded-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24955.81116221!2d-107.8820455!3d37.9374939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x873ed8469b8d60b7%3A0x88df5e7c8c577c82!2sTelluride%2C%20CO!5e0!3m2!1sen!2sus!4v1"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-200"></div>
            <div className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              <ExternalLink className="h-5 w-5 text-blue-600" />
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-2 text-center">Click map to open in Google Maps</p>
        </div>

        {/* Contact Section */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Contact</h2>
          <div className="space-y-4">
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-blue-600 mr-2" />
              <a 
                href="mailto:info@tellurideevents.com"
                className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
              >
                info@tellurideevents.com
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TellurideEvents;